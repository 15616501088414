/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_pencil_new': {
    width: 14,
    height: 15,
    viewBox: '0 0 14 15',
    data: '<path d="M7.73695 3.6715L2.94778 8.74066C2.76695 8.93316 2.59195 9.31233 2.55695 9.57483L2.34111 11.4648C2.26528 12.1473 2.75528 12.614 3.43195 12.4973L5.31028 12.1765C5.57278 12.1298 5.94028 11.9373 6.12111 11.739L10.9103 6.66983C11.7386 5.79483 12.1119 4.79733 10.8228 3.57816C9.53945 2.37066 8.56528 2.7965 7.73695 3.6715Z" stroke="#0277FF" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.93787 4.51953C7.1887 6.12953 8.49537 7.36036 10.117 7.5237" stroke="#0277FF" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
